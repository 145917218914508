define(['app'], function (app) {

  var footerBackToTop = function () {
    var component = {};
    var _config = {
      backToTopOffset: 500
    };

    var _init = function (element) {
      component.element = element;
      component.addClickEventListener();
    };

    var _addClickEventListener = function() {
      component.element.addEventListener('click', component.backToTop);
    };

    var _backToTop = function(event) {
      event.preventDefault();
      app.element.scrollTo(1, component.config.backToTopOffset);
    };

    component.config = _config;
    component.init = _init;
    component.addClickEventListener = _addClickEventListener;
    component.backToTop = _backToTop;

    return component;
  };

  return footerBackToTop;
});
